import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Link from "@mui/material/Link"
import { useStaticQuery, graphql } from "gatsby"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      site {
        siteMetadata {
          social {
            email
          }
        }
      }
    }
  `)

  const email = data.site.siteMetadata.social?.email

  return (
    <>
      <Layout>
        <Seo
          title="Contact"
          description="Help us to improve with your valuable feedback so that we can add the most demanded functionality much faster"
        />
        <h1>Contact</h1>
        <p>
          As we know, loan repayment process is different in various parts of
          the world and we are constantly looking forward to extend the features
          of this application.
        </p>
        <p>
          Please suggest us the functionality you would like to see and help us
          to understand the repayment process in your region.
        </p>
        <p>
          Feel free to write us at:{" "}
          <Link target="_blank" href={`mailto:${email}`} underline="hover">
            {email}
          </Link>
        </p>
      </Layout>
    </>
  )
}

export default Contact
